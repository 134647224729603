var user = JSON.parse(localStorage.getItem("user"))
//192.168.10.5
export default{
    data(){
        return{
            result_api : []
        }
    },
    async post_padrao(rota, json){
        var url = 'http://191.252.156.73:3002/api/'
        url += rota
        const config = {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Content-Type': 'application/json' 
            }
        }
        var request = new Request(url, config)
        await fetch(request)
            .then((resp) => resp.json())
            .then((data) => this.result_api = data)
        return this.result_api
    },
    async login(rota, parametros){
        var url = 'http://191.252.156.73:3002/api/'
        url += rota
        var str_params = ''
        for(var i = 0; i < parametros.length; i++){
            str_params += `/${parametros[i]}`
        }
        url += str_params
        console.log(url)
        await fetch(url)
            .then((resp) => resp.json())
            .then((data) => this.result_api = data)
        return this.result_api
    },
    async get(rota, parametros){
        if(user != undefined){
            var url_padrao = user.nm_caminho_api + '/'
        }
        var url = url_padrao
        url += rota
        var str_params = ''
        for(var i = 0; i < parametros.length; i++){
            str_params += `/${parametros[i]}`
        }
        url += str_params
        console.log(url)
        await fetch(url)
            .then((resp) => resp.json())
            .then((data) => this.result_api = data)
        return this.result_api
    },

    async post(rota, json){
        if(user != undefined){
            var url_padrao = user.nm_caminho_api + '/'
        }
        var url = url_padrao
        url += rota
        var json_sttringfy = JSON.stringify(json)
        var formData = new FormData()
        formData.append('json',json_sttringfy)
        for(var i in json.nm_arquivos){
            formData.append('file', json.nm_arquivos[i])
        }
        var headers = {
            'Content-Type': 'application/json'
        }
        headers = json.nm_arquivos===undefined?headers:{}
        const config = {
            method: "POST",
            body: json.nm_arquivos===undefined?json_sttringfy:formData,
            headers: headers
        }
        var request = new Request(url, config)
        await fetch(request)
            .then((resp) => resp.json())
            .then((data) => this.result_api = data)
        return this.result_api
    },

    async upload_file(file){
        if(user != undefined){
            var url_padrao = user.nm_caminho_api + '/'
        }
        var url = url_padrao
        url += `upload-file`
        console.log('URL ARQUIVO',url)
        var formData = new FormData()
        formData.append('file', file)
        const config = {
            method: "POST",
            body: formData,
        }
        var request = new Request(url, config)
        await fetch(request).then((resp)=>resp.json())
        .then((data) => this.result_api = data)
        return this.result_api
    }
}