<template>
  <DxScheduler 
    :data-source="appointments"
    :allow-deleting="true"
    :allow-updating="true"
    :views="views" 
    :current-view="currentView"
    :on-appointment-deleted="showDeletedToast"
    @appointment-form-opening="onAppointmentFormOpening"
    @appointment-added="onAppointmentAdded"
    @appointment-updating="onAppointmentUpdating"
    >
  </DxScheduler>
</template>

<script>
import { DxScheduler } from 'devextreme-vue/scheduler';
import { confirm } from 'devextreme/ui/dialog';
import api from '../api/api';

export default {
  components: {
    DxScheduler,
  },
  data() {
    return {
      cd_calendario:[],
      appointments: [],
      priorityText: '',
      views: ['day','month'],
      currentView: 'week',
      currentDate: new Date(),
      empresa:[],
      pessoas: [
        { nome: "Mateus"},
        { nome:"Henrique"},
        { nome: "Vini"}
      ],
    };
  },
  async created() {
    await this.loadAppointments();
    await this.loadEmpresa();
  },
  methods: {
    //CARREGA GRID
    async loadAppointments() {
      const response = await api.get('consulta_calendario', []);
      this.appointments = response;
      this.appointments = response.map(appointment => ({
      ...appointment,
      text: appointment.nm_titulo
    }));
    },

    //CADASTRA
    async onAppointmentAdded(e) {
      console.log(e)
      e.appointmentData.priorityId = this.priorityText;
      await api.post('cadastra_calendario', e.appointmentData);
      this.loadAppointments()
      console.log(e.appointmentData);
    },
    async loadEmpresa(){
      const response = await api.get('consulta_empresa',[])
      this.empresa = response;
    },

    //ITENS DO CADASTRO
    onAppointmentFormOpening(e) {
      const form = e.form;
      form.option("items", [
        {
          label: { text: "Título" },
          editorType: "dxTextBox",
          dataField: "nm_titulo",
        },
        {
          label: { text: "Pessoa" },
          editorType: "dxSelectBox",
          dataField: "nm_pessoa",
          editorOptions:{
            dataSource:this.pessoas,
            displayExpr:"nome",
            valueExpr:"nome"
          }
        },
        {
          label: { text: "Empresa" },
          editorType: "dxSelectBox",
          dataField: "cd_empresa",
          editorOptions:{
            dataSource:this.empresa,
            displayExpr:"nm_empresa",
            valueExpr:"cd_empresa"
          }
        },
        {
          label: { text: "Link" },
          editorType: "dxTextBox",
          dataField: "nm_link",
        },
        {
          label: { text: "Data" },
          editorType: "dxDateBox",
          dataField: "data_",
          editorOptions: {
            type: "datetime",
            displayFormat: "dd/MM/yyyy HH:mm",
            pickerType: "rollers",
          },
        },
        {
          label: { text: "Descrição" },
          editorType: "dxTextBox",
          dataField: "nm_descricao",
        },
      ]);
    },

    //DELETA ITEM
    async showDeletedToast(e){
      const result = await confirm("Deseja excluir esse agendamento?", "Confirmar")
      if(result){
        await api.get('deleta_calendario', [e.appointmentData.cd_calendario])
        this.loadAppointments()
      }
      else{
        this.loadAppointments()
      }
    },
    
    //ALTERA
    async onAppointmentUpdating(e) {
      console.log(e);
      // JUNTA OS DADOS ANTIGOS COM OS NOVOS
      const updatedData = { 
        ...e.oldData,
        ...e.newData,
        priorityId: this.priorityText
      };
      await api.post('altera_calendario', updatedData);
      this.loadAppointments();
      console.log(updatedData);
    },
  },
};
</script>