<template>
    <div>
        <h2 class="content-block">{{tela.text}}</h2>
        <DxForm :form-data="formData" class="box" v-if="tipo_tela == 1">
            <DxGroupItem
                :col-count="tela.quant"
                css-class="form-group"
            >
                <DxSimpleItem v-for="campo in campos" :editor-options="campo.options[0]" v-bind:key="campo.nm_campo" :data-field="campo.nm_campo" :editor-type="campo.tipo_campo" :template="campo.template">
                    <DxLabel :text="campo.options[0].label"/>
                </DxSimpleItem>
            </DxGroupItem>
            <template #DxColorBox="{ data }">
                 <DxColorBox v-model="formData[data.editorOptions.name]"/>
            </template>
            <template #img="{ data }">
                <button class="imgbutton" @click="OpenImage(formData[data.editorOptions.name])"><img max-width="180" height="80" :src="formData[data.editorOptions.name]"/></button>
            </template>
            <template #DxTagBox="{ data }">
                <dxTagBox v-model="formData[data.editorOptions.name]" :items="data.editorOptions.items" :display-expr="data.editorOptions.displayExpr" :value-expr="valueExpr"/>
            </template>
            <template #File-uploader="{ data }">
                <input type="file" id="nm_arquivos" @change="add_arquivos(data.editorOptions.name)" multiple>
            </template>
            <template #DxHtmlEditor="{ data }">
                <DxHtmlEditor
                  v-model="formData[data.editorOptions.name]"
                  height="725px"
                :element-attr="{ style: 'border-color: black;' }"
                >
                    <DxMediaResizing :enabled="true"/>
                    <DxImageUpload
                        :tabs="currentTabs"
                    />
                    <DxToolbar :multiline="true">
                      <DxItem name="undo"/>
                      <DxItem name="redo"/>
                      <DxItem name="separator"/>
                      <DxItem
                        name="size"
                        :accepted-values="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                        :options="{ inputAttr: { 'aria-label': 'Font size' } }"
                      />
                        <DxItem
                          name="font"
                          :accepted-values="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']"
                          :options="{ inputAttr: { 'aria-label': 'Font family' } }"
                        />
                        <DxItem name="separator"/>
                        <DxItem name="bold"/>
                        <DxItem name="italic"/>
                        <DxItem name="strike"/>
                        <DxItem name="underline"/>
                        <DxItem name="separator"/>
                        <DxItem name="alignLeft"/>
                        <DxItem name="alignCenter"/>
                        <DxItem name="alignRight"/>
                        <DxItem name="alignJustify"/>
                        <DxItem name="separator"/>
                        <DxItem name="orderedList"/>
                        <DxItem name="bulletList"/>
                        <DxItem name="separator"/>
                        <DxItem
                          name="header"
                          :accepted-values="headerValues"
                          :options="headerOptions"
                        />
                        <DxItem name="separator"/>
                        <DxItem name="color"/>
                        <DxItem name="background"/>
                        <DxItem name="separator"/>
                        <DxItem name="link"/>
                        <DxItem name="image"/>
                        <DxItem name="separator"/>
                        <DxItem name="clear"/>
                        <DxItem name="codeBlock"/>
                        <DxItem name="blockquote"/>
                        <DxItem name="separator"/>
                        <DxItem name="insertTable"/>
                        <DxItem name="deleteTable"/>
                        <DxItem name="insertRowAbove"/>
                        <DxItem name="insertRowBelow"/>
                        <DxItem name="deleteRow"/>
                        <DxItem name="insertColumnLeft"/>
                        <DxItem name="insertColumnRight"/>
                        <DxItem name="deleteColumn"/>
                    </DxToolbar>
                </DxHtmlEditor>
                
            </template>
        </DxForm>
        <DxList v-if="formData.nm_arquivos!=undefined"
            class="box"
            :allow-item-deleting="true"
            item-delete-mode="toggle"
            @item-deleted="deleted_item"
        >
            <DxItem v-for="arq in url_files" v-bind:key="arq">
                <div class="button_item_file">{{arq.url}} <a :href="arq.url" target="_blank" rel="noopener noreferrer">visualizar anexo</a></div>
            </DxItem>
        </DxList>
        <div class="box-buttons" v-if="tipo_tela == 1">
            <DxButton 
                v-if="cadastro"
                class="DxButton" 
                icon="check" 
                type="success" 
                text="Confirmar Cadastro" 
                @click="cadastrar()"/>
            <DxButton 
                v-else
                class="DxButton"
                icon="check" 
                type="success" 
                text="Confirmar Alteração" 
                @click="alterar()"/>
            <DxButton 
                class="DxButton" 
                icon="clear" 
                type="danger" 
                text="Limpar" 
                @click="limpar()"/>
            <DxButton 
                class="DxButton"
                icon="pulldown"
                type="default"
                text="Atualizar Lista"
                @click="carrega_dados()"/>
        </div>
        <DxDataGrid class="DxDataGrids"
            @exporting="onExporting"
            :data-source="dados_grid" 
            :key-expr="tela.nm_chave_grid" 
            :selection="{ mode: 'single' }"
            :columns="campos_grid" 
            :show-borders="true" 
            :focused-row-enabled="true" 
            :row-alternation-enabled="true"
            :column-auto-width="true"
            @selection-changed="onChange_linha"
        >
            <DxRemoteOperations :group-paging="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
            <DxPaging :page-size="10" />
            <DxFilterRow :visible="true"/>
            <DxHeaderFilter :visible="true"/>
            <DxExport
                :enabled="true"
                :allow-export-selected-data="true">
            </DxExport>
            <template #img="{data}">
                <button class="imgbutton" @click="OpenImage(data.value)"><img :src="data.value" max-width="130" height="45" alt=""></button>
            </template>
            <template #link="{data}">
                <div v-if="data.value!=undefind">
                    <a :href="data.value" target="_blank">Acessar</a>
                </div>
                <div v-else>
                    Nenhum arquivo anexado
                </div>
            </template>
            <template #ButtomGrid="{ data }">
                <div v-if="campos_grid[data.columnIndex]">
                    <DxButton :type="campos_grid[data.columnIndex].type" class="buttomgrid" :width="120" @click="exec_button(campos_grid[data.columnIndex].nm_api)">{{ campos_grid[data.columnIndex].caption }}</DxButton>
                </div>
            </template>
        </DxDataGrid>
    </div>
    <DxPopup
      :visible="show_popup"
      :shadding="true"
      height="50%"
      width="50%"
      :resize-enabled="true"
    >
      <DxToolbarItem
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="button_cancelar"
      />
      <img style='height: 90%; width: 100%; object-fit: contain' :src="item_click" alt="">
    </DxPopup>
</template>
<script>
import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxImageUpload,
} from 'devextreme-vue/html-editor';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
} from 'devextreme-vue/form';
import DxList,{DxItem} from 'devextreme-vue/list';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxTagBox from 'devextreme-vue/tag-box';
import {
    DxDataGrid,
    DxRemoteOperations,
    DxGroupPanel,
    DxGrouping,
    DxSearchPanel,
    DxPaging,
    DxScrolling,
    DxExport,
    DxFilterRow,
    DxHeaderFilter
} from 'devextreme-vue/data-grid'
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import DxColorBox from 'devextreme-vue/color-box';
import DxButton from 'devextreme-vue/button'
import api from '../api/api'
import notify from 'devextreme/ui/notify'
export default{
    data(){
        return{
            seleciona_grid: null,
            formData:{},
            campos: [],
            tela: {},
            cadastro: true,
            campos_grid: [],
            dados_grid: [],
            tipo_tela: 0,
            show_popup: false,
            button_cancelar: {
              width: 15,
              icon: 'close',
              type:'danger',
              onClick: ()=>{
                this.show_popup = false
              }
            },
            item_click: '',
            qt_itens_add: 0,
            url_files: [],
            atualiza: '',
            interval_atualizacao: ''
        }
    },
    watch:{
        '$route.path': function(){
            this.limpar_tudo()
            this.carrega_tela()
            this.carrega_dados()
            clearInterval(this.interval_atualizacao)
        }
    },
    created(){
        this.limpar_tudo()
        this.carrega_tela()
        this.carrega_dados()
    },
    methods:{
        deleted_item(e){
            console.log(e)
            this.url_files.splice(e.itemIndex,1)
            this.formData.nm_arquivos.splice(e.itemIndex,1)
        },
        async add_arquivos(id){
            var arq = document.getElementById(id).files
            this.formData[id] = []
            for(var a = 0; a < arq.length; a++){
                console.log(a)
                this.formData[id][a] = arq[a]
                this.url_files.push(await api.upload_file(arq[a]))
            }
            console.log(this.url_files)
        },
        async exec_button(api_button){
            var result = await api.post(api_button,this.seleciona_grid)
            notify(
                {message: result['Message'],type: 'success',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'}
            );
            this.carrega_dados();
        },
        getvmodel(data){
            return `formData.${data.name}`
        },
        campos_tela(){
            return this.campos.filter(i => i.show_tela == 'S')
        },
        async carrega_tela(){
            this.tela = JSON.parse(localStorage.getItem("tela"))
            this.tipo_tela = this.tela.cd_tipo_tela
            this.atualiza  = this.tela.nm_atualiza
            if(this.atualiza == 'S'){
                this.interval_atualizacao = setInterval(this.carrega_dados, 10000)
            }
            if(localStorage.getItem("campos") == undefined){
                this.campos = []
            }
            else{
                var campos_c = JSON.parse(localStorage.getItem("campos"))
                const promise = campos_c.map(async(element_campo)=>{
                    if(element_campo.tipo_campo == 'dxSelectBox' || element_campo.tipo_campo == 'DxTagBox'){
                        var parametro = element_campo.options[0].nm_parametro_api
                        if(parametro == undefined){
                            element_campo.options[0].items = await api.get(element_campo.options[0].nm_api,[])
                        }
                        else{
                            element_campo.options[0].items = await api.get(element_campo.options[0].nm_api,[parametro])
                        }
                    }
                    console.log(element_campo)
                })
                await Promise.all(promise)
                this.campos = campos_c.filter(c => c.show_tela == 'S')
            }
            if(localStorage.getItem("campos_grid") == undefined){
                this.campos_grid = []
            }
            else{
                this.campos_grid = JSON.parse(localStorage.getItem("campos_grid"))
                console.log(this.campos_grid)
            }
        },
        async cadastrar(){
            console.log(this.formData)
            var result = await api.post(this.tela.nm_caminho_cadastro, this.formData)
            console.log('RESULT', result)
            if(result.Code == 0 || result.code == 0){
                notify({message: result.Message,type: 'success',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'})
            }
            if(result.Code == 1 || result.code == 1){
                notify({message: result.Message,type: 'error',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'})
            }
            this.carrega_dados()
        },
        async alterar(){
            var result = await api.post(this.tela.nm_caminho_edita, this.formData)
            if(result.Code == 0 || result.code == 0){
                notify({message: result.Message,type: 'success',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'})
            }
            if(result.Code == 1 || result.code == 1){
                notify({message: result.Message,type: 'error',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'})
            }
            this.carrega_dados()
        },
        async carrega_dados(){
            console.log('CAMINHO CONSULTA', this.tela.nm_caminho_consulta)
            this.dados_grid = await api.get(this.tela.nm_caminho_consulta,[])
        },
        limpar(){
            this.formData = {}
            if(this.formData.nm_arquivos != undefined){
                document.getElementById('nm_arquivos').value = []
            }
            this.cadastro = true
            this.formData = {}
            this.url_files = []
        },
        limpar_tudo(){
            if(this.formData.nm_arquivos != undefined){
                document.getElementById('nm_arquivos').value = []
            }
            this.cadastro = true
            this.formData = {}
            this.dados_grid = []
            this.campos = []
            this.campos_grid = []
        },
        onChange_linha({selectedRowsData}){
            this.url_files = []
            const data = selectedRowsData[0]
            this.formData = data
            this.seleciona_grid = data
            if(this.formData.nm_arquivos != undefined){
                this.formData.nm_arquivos.split(',').forEach(element =>this.url_files.push({url : element}))
            }
            this.cadastro = false
        },
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function(options) {
                    options.excelCell.font = { name: 'Arial', size: 12 };
                    options.excelCell.alignment = { horizontal: 'left' };
                }
            }).then(function() {
                workbook.xlsx.writeBuffer()
                    .then(function(buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `datagrid.xlsx`);
                    })
            });
        },
        OpenImage(e){
            this.item_click = e
            this.show_popup = true
        }
    },
    components:{
        DxForm,
        DxSimpleItem,
        DxGroupItem,
        DxButton,
        DxDataGrid,
        DxRemoteOperations,
        DxGroupPanel,
        DxGrouping,
        DxSearchPanel,
        DxPaging,
        DxScrolling,
        DxLabel,
        DxColorBox,
        DxExport,
        DxPopup,
        DxToolbarItem,
        DxFilterRow,
        DxHeaderFilter,
        DxTagBox,
        DxList,
        DxItem,
        DxHtmlEditor,
        DxToolbar,
        DxMediaResizing,
        DxImageUpload,
    }
}
</script>
<style>
.box{
    margin: auto 5vh;
}
.box-buttons{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin: auto 5vh;
}
.DxButton {
    margin: 1vh 1vh;
}
.DxDataGrids {
    margin: 5vh;
}
.imgbutton{
    background-color: transparent;
    border-color: transparent;
}
.button_item_file{
    padding: 10px 20px;
}
.dx-htmleditor-content td, .dx-htmleditor-content th {
  border-color: #000000 !important;
  border-width: 1px !important;
  border-style: solid !important;
}
</style>