<template>
  <div>
    <div v-if="show_item">
      
    </div>
    <!--ADD ATIVO BEGIN-->
    <div v-if="show_add_ativo">
      <span class="span_p">Nenhum ativo encontrado, adicione ativos à esta localização</span>
        <DxSelectBox
          :data-source="ativos_select"
          v-model="cd_ativo"
          display-expr="nm_ativo"
          value-expr="cd_ativo"
          search-mode="contains"
          search-expr="nm_ativo"
          :search-enabled="true"
           @value-changed="onChangeAtivo"
        />
        <div class="infos_ativo">
          <div>
            <img :src="ativo_selecionado.nm_caminho_imagem" alt="" height="100px">
          </div>
          <div>
            <span class="span_p">Descrição:          </span>{{ ativo_selecionado.nm_descricao }}<br>
            <span class="span_p">Número de série:    </span>{{ ativo_selecionado.nm_serial_number }}<br>
            <span class="span_p">Fábricante:         </span>{{ ativo_selecionado.nm_fabricante }}<br>
            <span class="span_p">Quantidade de Us:   </span>{{ ativo_selecionado.qt_us }}<br>
            <span class="span_p">Cliente:            </span>{{ ativo_selecionado.nm_cliente }} <br>
            <span class="span_p">Proprietário:       </span>{{ ativo_selecionado.nm_proprietario }} <br>
            <span class="span_p">Data de instalação: </span>{{ ativo_selecionado.dt_instalacao }}
          </div>
          <div>
            <DxButton 
              v-if="ativo_selecionado.nm_caminho_imagem"
              class="DxButton" 
              icon="check" 
              type="success" 
              text="Adicionar ativo"
              @click="confirmar_cadastro"
            />
          </div>
        </div>
    </div>
    <!--ADD ATIVO END-->
    <div class="pai">
      <div v-for="linha in colunas" v-bind:key="linha" class="linhas_p" v-show="show_mapa">
        <div class="colunas_p" v-for="coluna in linhas" v-bind:key="coluna">
          <div class="item_loc" v-for="item in fase_nome(coluna, linha)" :key="item">
            <div v-if="item.cd_ativo == null" class="green" @click="adicionar_ativo(item)">
              .
            </div>
            <div v-else @click="busca_infos_ativo(item)" :style="{backgroundColor: item.nm_cor, color:item.nm_cor}" @mouseover="hover(item)" :id="'posicao'+item.cd_fase_localizacao">
              .
            </div>
            <DxTooltip
              :hide-on-outside-click="false"
              :target="'#posicao'+item.cd_fase_localizacao"
              show-event="mouseenter"
              hide-event="mouseleave"
            >
              <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br>
              <b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br>
              <b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br>
              <b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupadas) }}<br>
              <b>Ocupados: </b>{{ ativo_tooltip.ocupadas }}<br>
            </DxTooltip>
          </div>
        </div>
      </div>
    </div>
    <DxPopup
      v-if="show_item"
      :visible="show_item"
      height="100%"
    >
      <DxToolbarItem
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="button_cancelar"
      />
      <fase_localizacao :fase_localizacao="ativo_selecionado" :ativos="ativos" :cd_fase_localizacao="ativo.cd_fase_localizacao"/>
    </DxPopup>
  </div>
</template>
<script>
import api from '@/api/api'
import fase_localizacao from '@/empresa/fase_localizacao.vue'
import DxSelectBox from 'devextreme-vue/select-box';
import DxButton from 'devextreme-vue/button'
import { DxTooltip } from 'devextreme-vue/tooltip';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'

//import add_ativo from '@/components/add_ativo.vue'
export default{
  props:{
      fase: Object
    },
    data(){
      return{
        localizacoes: [],
        fase_l:[],
        load: true,
        linhas: 0,
        colunas: 0,
        tooltip: false,
        show_item: false,
        show_add_ativo: false,
        ativo: 0,
        ativos: [],
        ativo_selecionado: [],
        ativos_select: [],
        cd_ativo: 0,
        cd_fase_localizacao: 0,
        ativo_tooltip: {},
        show_mapa: true,
        show_lista: false,
        show_dashboard: false,
        button_cancelar: {
          width: 15,
          icon: 'close',
          type:'danger',
          onClick: ()=>{
            this.show_item = false
          }
        },
      }
    },
    components:{
      DxSelectBox,
      DxButton,
      fase_localizacao,
      DxTooltip,
      DxPopup,
      DxToolbarItem
      //add_ativo
    },
    async created(){
      await this.carrega_dados()
    },
    methods:{
      show(item){
        if(item == 1){
          this.show_mapa = true
          this.show_dashboard = false
          this.show_lista = false
        }
        else if(item == 2){
          this.show_mapa = false
          this.show_dashboard = false
          this.show_lista = true
        }
        else if(item == 3){
          this.show_mapa = false
          this.show_dashboard = true
          this.show_lista = false
        }
      },
      limpar(){
        this.ativo = 0
        this.ativo_selecionado = {}
        this.ativos = []
        this.show_item = false
        this.show_add_ativo = false
      },
      async carrega_dados(){
        var f = this.fase
        this.linhas = f.fase_infos.qt_linhas
        this.colunas = f.fase_infos.qt_colunas
        this.localizacoes = await api.get('consulta_fase_localizacao', [f.cd_fase])
        //console.log('LOCALIZAÇÕES',this.localizacoes)
        this.ativos_select = await api.get('consulta_ativo', [])
        this.load = false
      },
      fase_nome(coluna, linha){
        var item = this.localizacoes.filter(i => i.coluna == linha && i.linha == coluna)
        ////console.log('ITEM',item)
        return item==undefined?{nm_fase_localizacao:'...', cd_ativo: null}:item
      },
      async busca_infos_ativo(ativo){
        this.limpar()
        this.ativo = ativo
        this.ativo_selecionado = await api.get('consulta_ativo_item', [ativo.cd_ativo])
        this.ativos = await api.get('consulta_ativos_fase_localizacao', [ativo.cd_fase_localizacao])
        //console.log('ATIVOS',this.ativos)
        this.show_item = true
        this.show_add_ativo = false
      },
      async adicionar_ativo(item){
        this.limpar()
        this.ativo_selecionado = {}
        this.cd_ativo = 0
        this.show_add_ativo = true
        this.show_item = false
        this.ativo = item
      },
      async onChangeAtivo(){
        this.ativo_selecionado = await api.get('consulta_ativo_item', [this.cd_ativo])
        this.ativo_selecionado = this.ativo_selecionado[0]
      },
      async confirmar_cadastro(){
        var result = await api.get('add_ativo_fase_localizacao', [this.cd_ativo, this.ativo.cd_fase_localizacao])
        this.localizacoes = await api.get('consulta_fase_localizacao', [this.ativo.cd_fase])
        console.log(result)
        this.show_add_ativo = false
      },
      hover(e){
        this.ativo_tooltip = e
      }
    },
}
</script>
<style>
.pai{
  text-align: center;
}
.linhas_p{
  display: inline-table;
}
.colunas_p{
  font-size: 30px;
  text-align: center;
}
.item_loc{
  font-size: 6px;
  width: 10px;
  height: 10px;
  border: 0.2px solid rgb(107, 107, 107);
  margin: 0;
  padding: 0;
  color: white
}
.item_loc:hover{
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.524);
}
</style>