<template>
  <div>
    <h2 class="content-block">Usuário</h2>
    <div class="content-block dx-card responsive-paddings">
      <h2 class="subtitle is-2">Empresa: {{ user.nm_empresa }}</h2>
      <div class="empresa-image">
        <div class="form-avatar">
          <img :src="user.empresa_image" />
        </div>
        <div class="form-avatar">
          <img :src="user_image" />
        </div>
      </div>
    </div>

    <div class="content-block dx-card responsive-paddings">
      <div class="box">
        <DxTextBox
          label="Usuário"
          v-model="nome_user"
          :read-only="readonly"
        />
        <br>
        <DxTextBox
          label="Email"
          v-model="email_user"
          :read-only="readonly"
        />
        <br>
        <DxTextBox
          label="Alterar senha"
          v-model="senha_user"
          :read-only="readonly"
          @value-changed="Valida_senha"
        />
        <br>
        <DxTextBox
          label="Link para Imagem"
          v-model="user_image"
          :read-only="readonly"
        />
        <br>
        <DxButton
          v-show="readonly"
          text="Alterar informações"
          type="default"
          styling-mode="contained"
          @click="editar()"
        />
        <DxButton
          v-show="!readonly"
          text="Confirmar"
          type="success"
          styling-mode="contained"
          @click="confirmar()"
        />
      </div>
      <div class="box" style="margin-left: 60px;margin-top: 10px;color: red;">
        {{ mensagem_result }}
      </div>
    </div>
  </div>

  <DxPopup
      :visible="show_popup"
      :shadding="true"
      height="50%"
      width="50%"
      :resize-enabled="true"
    >
      <DxToolbarItem
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="button_cancelar"
      />
      <img style="height: 90%; width: 100%; object-fit: contain" :src="item_click" alt="">
    </DxPopup>
 
</template>

<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import api from '@/api/api';
import notify from 'devextreme/ui/notify'

export default {
  data() {
    return {
      user: {},
      show_popup: false,
      item_click: '',
      button_cancelar: {
        width: 15,
        icon: 'close',
        type:'danger',
        onClick: ()=>{
          this.show_popup = false
        }
      },
      mensagem_result: '',
      nome_user: '',
      email_user: '',
      user_image: '',
      senha_user: '',
      readonly: true,
      caracter_especial: false,
      letra_maiuscula: false,
      minimo_senha: false
    }
  },
  created() {
    this.carrega_dados()
  },
  methods: {
    carrega_dados() {
      var json = JSON.parse(localStorage.getItem('user'))
      this.user = json
      this.nome_user = this.user.nm_usuario
      this.user_image = this.user.user_image
      this.email_user = this.user.nm_login
    },
    abrirImagem(url) {
      this.item_click = url;
      this.show_popup = true;
    },
    editar(){
      this.readonly = false
    },
    async confirmar(){
      var json = {
        nome: this.nome_user,
        email: this.email_user,
        imagem: this.user_image,
        senha: this.senha_user,
        cd_usuario: this.user.cd_usuario
      }
      if(this.senha_user == ''){
        notify({message: 'Digite a senha para atualizar os dados',type: 'error',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'})
        return
      }
      let r = /^(?=.*\d)(?=.*[a-z])/;
      let a = /(?=.*[A-Z])/;
      let b = /(?=.*[$*&@#])/;
      if(r.test(this.senha_user)){
        if(!a.test(this.senha_user)){
          this.mensagem_result = 'Senha inválida, verifique se existe pelo menos um caracter especial e uma letra maiusula'
          return
        }
        if(!b.test(this.senha_user)){
          this.mensagem_result = 'Senha inválida, verifique se existe pelo menos um caracter especial e uma letra maiusula'
          return
        }
        var resp = await api.post_padrao('altera_usuario',json)
        if(resp.code == 0){
          notify({message: resp.Message,type: 'success',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'})
          this.$router.push('/')
        }
        else{
          notify({message: resp.Message,type: 'error',displayTime: 3500,animation: {show: {type: 'fade', duration: 400, from: 0, to: 1,},hide: { type: 'fade', duration: 40, to: 0 },},},{position: 'bottom center'})
          this.readonly = true
        }
      }
      else{
        console.log('tesste')
        this.mensagem_result = 'Senha inválida, verifique se existe pelo menos um caracter especial e uma letra maiusula'
      }
    }
  },
  components: {
    DxTextBox,
    DxButton,
    DxPopup,
    DxToolbarItem
  }
};
</script>

<style lang="scss">
.empresa-image,
.user-image {
  display: flex;
  align-items: center;
  margin-bottom: 20px; 
}

.form-avatar {
  margin-right: 10px; /* Ajusta o espaço entre a imagem e o botão */
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;
  img {
    width: 200px;
    display: block;
    margin: 0 auto;
  }
}

.dx-button {
  margin-left: 10px; /* Ajusta o espaço entre o botão e a imagem */
}
</style>