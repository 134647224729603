<template>
  <div class="login-form">
    <DxTextBox
      v-model="login"
      label="Email"
    />
    <br>
    <DxTextBox
      v-model="password"
      label="Senha"
      :mode="passwordMode"
      v-input:keyup.enter="log_in()"
    >
      <DxTextBoxButton
        :options="passwordButton"
        name="password"
        location="after"
      />
    </DxTextBox>
    <br>
    <br>
    <DxButton
      icon="user"
      type="success"
      text="Login"
      @click="log_in()"
    />
    <DxToast
      :visible="isVisible"
      :message="message"
      :type="type"
    />
  </div>
</template>

<script>
import api from '../api/api';

import {DxTextBox, DxButton as DxTextBoxButton} from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import { DxToast } from 'devextreme-vue/toast';


export default {
  data() {
    return {
      isVisible: false,
      message: '',
      type: 'info',
      loading: false,
      login: '',
      password: '',
      rememberMe: '',
      passwordMode: 'password',
      passwordButton: {
        icon: "unlock",
        onClick: () => {
          this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
        },
      }
    }
  },
  methods: {
    onPressEnter: function(e) {
      if(e.keyCode === 13){
        this.log_in()
      }
    },
    async log_in() {
      this.loading = true
      var result = await api.login('login', [this.login, this.password]);
      if(result.code == 0){
        localStorage.setItem("logado", 'S')
        localStorage.setItem("user", JSON.stringify(result.user))
        localStorage.setItem("cd_usuario", result.user.cd_usuario)
        this.loading = false
        this.$router.push('/home')
      }
      else{
        this.message = result.mensagem
        this.type = 'error'
        this.isVisible = true
      }
      this.loading = false
    },
  },
  components: {
    DxTextBox,
    DxTextBoxButton,
    DxToast,
    DxButton
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-form {
    text-align: center;
    font-size: 16px;
    font-style: normal;
}
</style>