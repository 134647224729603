<template>
    <div>
      <h2 class="content-block">Cadastro e Alteração de Telas</h2>
      <span class="content-block" style="font-size: medium;">Código da tela: {{ cd_tela_empresa }}</span>
      <div class="box-buttons">
        <DxSelectBox
          class="DxTextBox"
          :data-source="tipo_telas"
          display-expr="nm_tela"
          value-expr="cd_tela"
          label="TELA"
          v-model="cd_tela"
        />    
        <DxTextBox
          v-model="nm_tela"
          label="NOME DA TELA"
          class="DxTextBox"
        />
        <DxNumberBox
          v-model="qt_colunas"
          label="QUANTIDADE DE COLUNAS"
          class="DxTextBox"
          :show-spin-buttons="true"
          :show-clear-button="true"
        />
        <DxSelectBox
          class="DxTextBox"
          :data-source="empresas"
          display-expr="nm_empresa"
          value-expr="cd_empresa"
          label="EMPRESA"
          v-model="cd_empresa"
        />
        <DxSelectBox
          class="DxTextBox"
          :data-source="menus"
          display-expr="nm_menu"
          value-expr="cd_menu_tela"
          label="MENU"
          v-model="cd_menu_telas"
        />
        <DxSelectBox
          class="DxTextBox"
          :data-source="tipos_tela"
          display-expr="nm_tipo_tela"
          value-expr="cd_tipo_tela"
          label="TIPO DA TELA"
          v-model="cd_tipo_tela"
        />
        <DxTextBox
          v-model="nm_caminho_consulta"
          label="API CONSULTA"
          class="DxTextBox"
        />
        <DxTextBox
          v-model="nm_caminho_cadastro"
          label="API CADASTRA"
          class="DxTextBox"
        />
        <DxTextBox
          v-model="nm_caminho_edita"
          label="API ALTERAÇÃO"
          class="DxTextBox"
        />
        <DxTextBox
          v-model="nm_atualiza"
          label="TELA ATUALIZA AUTOMATICAMENTE"
          class="DxTextBox"
        />
        <DxTextBox
          v-model="nm_chave_grid"
          label="CHAVE DA TELA"
          class="DxTextBox"
        />
      </div>
      <div class="box">
        <DxButton
          v-if="status_cadastro"
          class="DxTextBox"
          icon="save"
          type="success"
          text="Confirmar cadastro"
          @click="cadastra_tela()"
        />
        <DxButton
          v-else
          class="DxTextBox"
          icon="save"
          type="outline"
          text="Confirmar Alteração"
          @click="altera_tela()"
        />
        <DxButton
          class="DxTextBox"
          icon="clear"
          type="danger"
          text="Limpar"
          @click="limpar()"
        />
      </div>
      <DxDataGrid
        class="DxDataGrids"
        :data-source="telas"
        key-expr="cd_tela_empresa"
        :selection="{ mode: 'single' }"
        :columns="columns"
        :show-borders="true"
        :focused-row-enabled="true"
        :row-alternation-enabled="true"
        :column-auto-width="true"
        @selection-changed="onSelectedLote"
      >
        <DxRemoteOperations :group-paging="true"/>
        <DxGroupPanel :visible="true"/>
        <DxGrouping :auto-expand-all="true"/>
        <DxScrolling column-rendering-mode="virtual"/>
        <DxSearchPanel
          :visible="true"
          :highlight-case-sensitive="true"
        />
        <DxPaging :page-size="10"/>
      </DxDataGrid>
    </div>
</template>

<script>
import api from '../api/api'
import {
DxDataGrid,
DxRemoteOperations,
DxGroupPanel,
DxGrouping,
DxSearchPanel,
DxPaging,
DxScrolling
} from 'devextreme-vue/data-grid'
import DxButton from 'devextreme-vue/button'
import DxTextBox from 'devextreme-vue/text-box'
import DxSelectBox from 'devextreme-vue/select-box'
import DxNumberBox from 'devextreme-vue/number-box'
export default {
  props: {
    picture: String
  },
  data() {
    return {
        status_cadastro:true,
        tipo_telas: [],
        cd_tela: 0,
        nm_tela: '',
        qt_colunas:0,
        empresas: [],
        cd_empresa: 0,
        menus: [],
        cd_menu_telas: 0,
        tipos_tela: [],
        cd_tipo_tela: 0,
        nm_caminho_consulta: '',
        nm_caminho_cadastro: '',
        nm_caminho_edita: '',
        nm_chave_grid: '',
        cd_tela_empresa: 0,
        nm_atualiza: '',
        telas: [],
        columns: [
            'cd_tela',
            'nm_tela_empresa',
            'qt_colunas',
            'cd_empresa',
            'cd_menu_tela',
            'cd_tipo_tela',
            'nm_caminho_consulta',
            'nm_caminho_cadastro',
            'nm_caminho_edita',
            'nm_chave_grid',
        ]
    }
  },
  created(){
      this.carrega_dados()
  },
  methods:{
    async carrega_dados(){
        this.menus =      await api.get('consulta_menu_tela', [])
        this.tipo_telas = await api.get('consulta_tipo_telas', [])
        this.tipos_tela = await api.get('consulta_tipos_tela', [])
        this.empresas =   await api.get('consulta_empresa',[])
        this.telas =      await api.get('consulta_telas', [])
    },
    async cadastra_tela(){
        var result = await api.post('cadastra_telas', this.gera_json())
        this.cd_tela_empresa = result.cd_tela_empresa
        console.log(result)
    },
    gera_json(){
        var json = {
            'cd_tela': this.cd_tela,
            'nm_tela_empresa': this.nm_tela,
            'qt_colunas': this.qt_colunas,
            'cd_empresa':this.cd_empresa,
            'cd_menu_tela':this.cd_menu_telas,
            'cd_tipo_tela':this.cd_tipo_tela,
            'nm_caminho_consulta':this.nm_caminho_consulta,
            'nm_caminho_cadastro':this.nm_caminho_cadastro,
            'nm_caminho_edita':this.nm_caminho_edita,
            'nm_chave_grid':this.nm_chave_grid,
            'nm_atualiza': this.nm_atualiza
        }
        return json
    },
    limpar(){
        this.cd_tela = ''
        this.nm_tela = ''
        this.qt_colunas = ''
        this.cd_empresa = ''
        this.cd_menu_telas = ''
        this.cd_tipo_tela = ''
        this.nm_caminho_consulta = ''
        this.nm_caminho_cadastro = ''
        this.nm_caminho_edita = ''
        this.nm_chave_grid = ''
        this.cd_tela_empresa = 0
        this.nm_atualiza = ''
        this.status_cadastro = true
    },
    async altera_tela(){
      var results = await api.post(`altera_tela/${this.cd_tela_empresa}`, this.gera_json())
      console.log(results)
      this.limpar()
      this.carrega_dados()
    },
    onSelectedLote({selectedRowsData}){
        console.log(selectedRowsData[0])
        var data = selectedRowsData[0]
        this.cd_tela = data.cd_tela
        this.nm_tela = data.nm_tela_empresa
        this.qt_colunas = data.qt_colunas
        this.cd_empresa = data.cd_empresa
        this.cd_menu_telas = data.cd_menu_tela
        this.cd_tipo_tela = data.cd_tipo_tela
        this.nm_caminho_consulta = data.nm_caminho_consulta
        this.nm_caminho_cadastro = data.nm_caminho_cadastro
        this.nm_caminho_edita = data.nm_caminho_edita
        this.nm_chave_grid = data.nm_chave_grid
        this.cd_tela_empresa = data.cd_tela_empresa
        this.nm_atualiza = data.nm_atualiza
        this.status_cadastro = false
    }
  },
  components: {
    DxDataGrid,
    DxRemoteOperations,
    DxGroupPanel,
    DxGrouping,
    DxSelectBox,
    DxSearchPanel,
    DxButton,
    DxPaging,
    DxScrolling,
    DxTextBox,
    DxNumberBox
  }
}
</script>
  
<style lang="scss">
.DxTextBox{
    margin: 1vh 1vh;
}
</style>