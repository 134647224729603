<template id="tela_tamanho">
  <div class="tela_tamanho">
    <h2 class="content-block">Localização</h2>
    <ul class="colunas" v-for="l in linha_pavimento" :key="l">
      <li class="linhas" v-for="c in l.qt_colunas" :key="c">
        <div v-for="item in load_fase(c,l)" :key="item.selected_fase">
          <div class="item" v-for="fase in item.fase" v-bind:key="fase">
            <div class="titulo">{{ fase.fase_infos.nm_fase }}</div>
            <div class="component">
              <kanbanp_popup :fase="fase"/>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <DxLoadPanel
      :position="{of:'#tela_tamanho'}"
      :visible="load"
      :show-indicator="true"
      :show-pane="true"
      hide-on-outside-click="true"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import kanbanp_popup from '@/components/kanbanp_popup'
import api from '@/api/api'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
//import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
//import DxPieChart, {
//  DxSeries,
//  DxLegend,
//  DxLabel
//} from 'devextreme-vue/pie-chart'
  export default{
    data(){
      return{
        linha_pavimento: [],
        coluna_pavimento: [],
        fases:[],
        load: true,
        fase_infos : [],
      }
    },
    components:{
      DxLoadPanel,
      kanbanp_popup
    },
    async created(){
      await this.carrega_dados()
    },
    methods:{
      async carrega_dados(){
        this.fases = await api.get('consulta_fase', [])
        this.linha_pavimento = await api.get('consulta_linha_pavimento',[])
        this.coluna_pavimento = await api.get('consulta_coluna_pavimento',[])
        this.fase_infos = await api.get('consulta_locais_vagos_ocupados', [])
        this.load = false
      },
      load_fase(c,l){
        try{
          var fase_ = this.coluna_pavimento.filter((cp)=>cp.cd_linha_pavimento == l.cd_linha_pavimento && cp.cd_item == c)
          console.log('FASES',this.fases)
          console.log('FASE_',fase_)
          this.fases.forEach((f)=>{
            fase_.forEach((f_, index)=>{
              if(f_.cd_fase == f.cd_fase){
                var infos = this.fase_infos.filter((d)=>d.cd_fase == f.cd_fase)
                var infos_r = [
                  {
                    'titulo': 'vagos',
                    'quant':infos[0].vagos
                  },
                  {
                    'titulo': 'ocupados',
                    'quant':infos[0].ocupados
                  },
                ]
                fase_[index]['fase_infos'] = f
                fase_[index]['fase_dados'] = infos_r
              }
            })
          })
          
          var selected_fase = this.fases
          var colunas = selected_fase.qt_colunas
          var linhas = selected_fase.qt_linhas
          var res = [{
            'linhas': linhas,
            'colunas': colunas,
            'fase': fase_,
            'selected_fase':selected_fase,
          }]
          console.log('RES', res)
          return res
        }
        catch{
          console.log('erro')
        }
      },
      calcula_altura(linhas){
        var altura = 25
        if(linhas <= 10){
          console.log('altura something')
          altura = 25
        }
        else if(linhas <= 20){
          altura = 25
        }
        altura = altura * linhas
        return altura + 'px'
      },
      calcula_largura(colunas){
        var largura = 50
        if(colunas == 5){
          largura = 80
        }
        else if(colunas <= 10){
          largura = 80
          console.log('something')
        }
        largura = largura * colunas
        return largura + 'px'
      }
    }
  }
</script>
<style>
.linhas {
  display: table-cell;
  vertical-align: top;
}
.colunas {
  text-align: center;
  display: table;
  table-layout: fixed;
}
.item{
  display: block;
  margin:3px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
  transition: 0.3s;
  background-color: #cacdcf;
}
.titulo{
  width: 100%;
  height: 25px;
  background-color: #1185B2;
  text-align: center;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.component{
  width: 100%; 
}
.grafico{
  margin-top: 5px;
}
.tela_tamanho{
  width: 100%;
  overflow: scroll;
}
.linhas_g {
  display: inline-table;
}
.colunas_g {
  text-align: center;
}
.but{
  margin: 0px;
  width: 10px;
  height: 20px;
  font-size: 10px;
}
.r{
  background-color: red;
}
.g{
  background-color: lightblue;
}
</style>