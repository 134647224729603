<template>
    <div>
        <h2 class="content-block">Cadastro e Alteração de Ativos</h2>
        <div class="box-buttons">
            <DxSelectBox class="DxTextBox" :data-source="itens" display-expr="nm_item" value-expr="cd_item" search-expr="nm_item" label="Item" v-model="cd_item" show-data-before-search="false"/>
            <DxTextBox   class="DxTextBox" v-model="nm_serial_number" label="Número de Série"/>
            <DxSelectBox class="DxTextBox" :data-source="clientes" display-expr="nm_cliente" value-expr="cd_cliente" search-expr="nm_cliente" label="Cliente" v-model="cd_cliente" show-data-before-search="false"/>
            <DxSelectBox class="DxTextBox" :data-source="proprietarios" display-expr="nm_proprietario" value-expr="cd_proprietario" search-expr="nm_proprietario" label="Proprietário" v-model="cd_proprietario" show-data-before-search="false"/>
            <DxTextBox   class="DxTextBox" v-model="cd_mudanca" label="Código da Mudança"/>
            <DxTextBox   class="DxTextBox" v-model="nm_nf" label="Nota Fiscal"/>
            <DxDateBox   class="DxTextBox" v-model="dt_instalacao" label="Instalaçãos" :input-attr="{ 'aria-label': 'Date' }" type="date"/>
            <DxTextBox   class="DxTextBox" v-model="nm_chave" label="Chave"/>
            <DxTextBox   class="DxTextBox" v-model="nm_tag_rfid" label="Tag RFID"/>
            <DxTextBox   class="DxTextBox" v-model="nm_observacao" label="Observação"/>
        </div>
    </div>
</template>
<script>
//import DxButton from 'devextreme-vue/button'
import DxTextBox from 'devextreme-vue/text-box'
import DxSelectBox from 'devextreme-vue/select-box'
import DxDateBox from 'devextreme-vue/date-box'
export default{
    data(){
        return{
            itens: [],
            cd_item: 0,
            nm_serial_number: '',
            clientes: [],
            cd_cliente: 0,
            proprietarios: [],
            cd_proprietario: 0,
            cd_mudanca: '',
            nm_nf: '',
            dt_instalacao: '',
            nm_chave: '',
            nm_tag_rfid: '',
            nm_observacao: ''
        }
    },
    components:{
        //DxButton,
        DxTextBox,
        DxSelectBox,
        DxDateBox
    },
    methods:{

    }
}
</script>
<style>
</style>